import React from "react";
import "./index.css";

const TopHeader = ({ changeLn, t, setlogin }) => {
  const handleLanguageChange = (e) => {
    changeLn(e.target.value);
  };

  return (
    <>
      <div className="container top-header p-3">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <i className="fa fa-phone"></i>
            <div className="small">
              {t("callUs")}: <strong>+8125656555, 32563131654</strong>
            </div>
            <span className="small">|</span>
            <i className="fa fa-envelope"></i>
            <div className="small">{t("email")}: gmmabdur@yahoo.com</div>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="mainwrap">
              <i className="fa fa-user"></i>
              <div className="small">{t("myAcc")}</div>
              <ul className="wrap">
                <li
                  style={{ margin: "5px", padding: "5px" }}
                  onClick={() => setlogin(true)}
                >
                  Login
                </li>
                <li style={{ margin: "5px", padding: "5px" }}>Register</li>
              </ul>
              <i className="fa fa-chevron-down"></i>
            </div>

            <span className="small">|</span>
            <i className="fa fa-heart"></i>
            <div className="small">{t("wishList")} (0)</div>
            <span className="small">|</span>
            <div className="small-gap"></div>
            <span className="small">|</span>
            <select
              className="language"
              name="language"
              id="language"
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="jp">Japanese</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bb"></div>
    </>
  );
};

export default TopHeader;
