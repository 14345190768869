import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { cartOperations } from "../../state/ducs/cart";
import "./CartAccount.css";
import Account from "./icon-account.png";
import Download from "./icon-download.png";
import Login1 from "./icon-login.png";
import Register from "./icon-register.png";
import Orderhistory from "./order-history.png";
import shopping from "./shopping-cart (1).png";


const CartAccount = ({ cartItems, removeFromCart, login, setlogin, setgolobalKeyword, setSearchKeyword, searchKeyword }) => {


    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [showAccount, setShowAccount] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showRecent, setShowRecent] = useState(false)



    const handleClose = () => { setShow(false); setShowAccount(false) };
    const handleClose2 = () => { setShow(false); setShowSearch(false) };
    const handleClose3 = () => { setShow(false); setShowRecent(false) };



    const handleShow = () => setShow(true);
    const handleShowAccount = () => setShowAccount(true);
    const handleSetShowSearch = () => setShowSearch(true);
    const handleSetShowRecent = () => setShowRecent(true);


    let subtotalPrice = 0;
    for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        subtotalPrice += parseInt(element.regular_price * element.quantity)
    }

    const handelSearch = (e) => {

        setgolobalKeyword(searchKeyword);
        navigate('/search')
    }


    //get the search keyword which was typed in search field

    //set that keyword to a root parent component
    //navigate to search result page and make API call there



    return (

        <div className="CartAccount">
            <div className="">
                <a class="sticky-mycart" data-target="popup" data-popup="#popup-mycart" onClick={handleShow}>
                    <span>Cart</span>
                    <i class="fa fa-shopping-cart"></i>
                </a>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton className='ModalTitle'>
                        <Modal.Title >
                            <div className="shoppingCart">
                                <i className='fa fa-shopping-cart'></i>
                                <h5>SHOPPING CART</h5>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="cartitemsLength">
                            <h6>There are {cartItems.length}  item(s) in your cart</h6>
                        </div>

                        {cartItems.map((cart) => (
                            <div className="row">
                                <div className="col-md-4 imageCart">
                                    <img src={cart.imageUrl} width={"100%"} height={100} alt="" />
                                </div>
                                <div className="col-md-4">
                                    {cart.name}
                                </div>
                                <div className="col-md-1">
                                    {cart.quantity}
                                </div>
                                <div className="col-md-2 cartFont">
                                    ¥{cart.regular_price}
                                </div>
                                <div className="col-md-1">
                                    <i className="fa fa-trash" onClick={() => removeFromCart(cart)}></i>
                                </div>
                            </div>
                        ))}
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <td class="text-left"><strong>Sub-Total</strong></td>
                                    <td class="text-end" style={{ fontSize: "20px" }}>¥{subtotalPrice}</td>
                                </tr>
                                <tr>
                                    <td class="text-left"><strong>Total</strong></td>
                                    <td class="text-end" style={{ fontSize: "20px" }}>¥{subtotalPrice}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Modal.Body>

                    <div className="row cartButton">
                        <div className="col-md-6">
                            <Link to="./viewPage">
                                <Button variant="secondary" >
                                    VIEWCART
                                </Button>
                            </Link>

                        </div>
                        <div className="col-md-6">
                            <Link to="./checkout">
                                <Button variant="primary" >
                                    CHECKOUT
                                </Button>
                            </Link>
                        </div>
                    </div>

                </Modal>
            </div>
            <div className="">
                <a class="sticky-myaccount" data-target="popup" data-popup="#popup-myaccount" onClick={handleShowAccount}>
                    <span>Account</span>
                    <i class="fa fa-user"></i>
                </a>
                <Modal show={showAccount}
                    onHide={handleClose}
                >
                    <Modal.Header closeButton className='ModalTitle'>
                        <Modal.Title >
                            <div className="shoppingCart">
                                <i className='fa fa-user'></i>
                                <h5>MY ACCOUNT</h5>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="languageAccount">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <span>Currency</span>
                                    <p>¥YEN</p>
                                </div>
                                <div className="col-md-6 ">
                                    <span>Language</span>
                                    <p>ENGLISH</p>
                                </div>
                            </div>
                        </div>
                        <div className="row AccountRow">
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={Orderhistory} alt="" />
                                </div>
                                <Link to="./login">
                                    <a href="">HISTORY</a>
                                </Link>
                            </div>
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={shopping} alt="" />
                                </div>
                                <Link to="./viewPage">
                                    <a href="">SHOPPING CART</a>
                                </Link>
                            </div>
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={Register} alt="" />
                                </div>
                                <Link to="/register">
                                    <a href="">REGISTER</a>
                                </Link>
                            </div>
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={Account} alt="" />
                                </div>
                                <Link to="./login">
                                    <a href="">ACCOUNT</a>
                                </Link>
                            </div>
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={Download} alt="" />
                                </div>
                                <Link to="./login">
                                    <a href="" >DOWNLOAD</a>
                                </Link>
                            </div>
                            <div className="col-md-4 form-box">
                                <div className="">
                                    <img src={Login1} alt="" />
                                </div>
                                <a href="" onClick={(e) => { e.preventDefault(); setlogin(true) }}>LOGIN</a>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
            <div className="">
                <a class="sticky-mysearch" data-target="popup" data-popup="#popup-mysearch" onClick={handleSetShowSearch}>
                    <span>Search</span>
                    <i class="fa fa-search"></i></a>
                <Modal show={showSearch}
                    onHide={handleClose2}
                >
                    <Modal.Header closeButton className='ModalTitle'>
                        <Modal.Title >
                            <div className="shoppingCart">
                                <i className='fa fa-search'></i>
                                <h5>SEARCH</h5>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <div className="row">

                                <div className="form-box">
                                    <input type="text" name="search" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} placeholder="Search" id="input-search" class="field" />
                                    <i class="fa fa-search sbmsearch"></i>
                                </div>
                                <div className="text-center">
                                    <button type="button" onClick={handelSearch} id="button-search" class="btn button-search">Search</button>
                                </div>
                            </div>

                        </div>

                    </Modal.Body>
                </Modal>
            </div>
            <div className="">
                <a class="sticky-recent" data-target="popup" data-popup="#popup-recent" onClick={()=>setShowRecent((true))}>
                    <span>Recent </span>
                    <i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                    <Modal show={showRecent}
                        onHide={handleClose3}
                    >
                        <Modal.Header closeButton className='ModalTitle'>
                            <Modal.Title >
                                <div className="shoppingCart">
                                    <i className='fa fa-eye-slash'></i>
                                    <h5>RECENT</h5>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="">
                                ASGVBS

                            </div>

                        </Modal.Body>
                    </Modal>
                
            </div>
        </div>

    )
};
const mapStateToProps = (state) => {
    return { cartItems: state.cart };
};
const mapDispatchToProps = {
    removeFromCart: cartOperations.removeFromCart

};

export default connect(mapStateToProps, mapDispatchToProps)(CartAccount);