import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NewArrivalsForCatPage from "../../Components/NewArrivals/NewArrivalsForCatPage";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import "./index.css";

const CategoryDetails = ({ t }) => {
  const [newArrivalProducts, setnewArrivalProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const {categoryId}= useParams();


  useEffect(() => {
    if (searchQuery.length) {
      return;
    }
    fetch("https://faizainc-backend.techknowgram.net/api/products/"+categoryId)
      .then((response) => response.json())
      .then((data) => setnewArrivalProducts(data));
  }, [searchQuery]);

  const onSearchHandler = () => {
    fetch(
      "https://faizainc-backend.techknowgram.net/api/search?q=" + searchQuery
    )
      .then((response) => response.json())
      .then((data) => setnewArrivalProducts(data));
  };

  const onResetAllHandler = () => {
    setSearchQuery("");
  };

  return (
    <div className="categoryDetailsContainer">
      <CartBannerComponent title={newArrivalProducts.length ? newArrivalProducts[0].categories[0].name:"New Arrivals"} />
      <div className="container">
        <div className="row">
          <div className="col-md-2 my-5">
            <div className="search-filters-container">
              <h4 className="text-uppercase">Shop By</h4>
              <div className="">
                <h6>Search</h6>
                <div className="wrapper d-flex align-items-center">
                  <input
                    className="searchInput"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    type="text"
                  />
                  <button onClick={onSearchHandler} className="btn btn-primary">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <h6 className="pt-3">Manufacturer</h6>
              <input
                className="mr-2"
                type="checkbox"
                name="menufacturer"
                id="menufacturer"
              />
              <label htmlFor="menufacturer">Canon(1)</label>
              <h6 className="pt-3">Select</h6>
              <div>
                <input className="mr-2" type="checkbox" name="red" id="red" />
                <label htmlFor="red">Red</label>
              </div>
              <div>
                <input className="mr-2" type="checkbox" name="blue" id="blue" />
                <label htmlFor="blue">Blue</label>
              </div>
              <button
                onClick={onResetAllHandler}
                className="btn btn-danger mt-3"
              >
                Reset All
              </button>
            </div>
          </div>
          <div className="col-md-10">
            <NewArrivalsForCatPage
              newArrivalProducts={newArrivalProducts}
              t={t}
              title={newArrivalProducts.length ? newArrivalProducts[0].categories[0].name:"New Arrivals"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDetails;
