import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cartOperations } from "../../state/ducs/cart";
import { globalOperations } from "../../state/ducs/globalState";
const NewArrivalsCard = (props) => {
  const addToCartHandler = () => {
    props.addToCart(props.productObj);
    props.showAddToCartModal();
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="newArrivalsCardContainer">
      <Link to={"/productDetails/"+props.productObj.id}>
        <img
          src={props.productObj.imageUrl}
          class="img-fluid w-100"
          alt="Banner Image"
        ></img>
      </Link>
      <div className="tools d-flex justify-content-center">
        <i className="fa fa-heart"></i>
        <i className="fa fa-exchange"></i>
        <i className="fa fa-search"></i>
      </div>
      <div className="body-overlay">
        <div className="d-flex justify-content-center stars">
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
        </div>
        <h5 className="text-center title">
          <Link to={"/productDetails/"+props.productObj.id}>
            {props.productObj.name.substr(0, 16)}
          </Link>
        </h5>
        <h5 className="text-center price">{`¥${numberWithCommas(
          props.productObj.regular_price
        )}`}</h5>
        <div onClick={addToCartHandler} className="text-center addCart">
          {props.t("addc")}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { cartItems: state.cart };
};

// Maps `dispatch` to `props`:
const mapDispatchToProps = {
  addToCart: cartOperations.addToCart,
  showAddToCartModal: globalOperations.showAddToCartModal,
};

// Connect them:
export default connect(mapStateToProps, mapDispatchToProps)(NewArrivalsCard);
