import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { numberWithCommas } from '../../utils';
import CartBannerComponent from "./CartBannerComponent";
import "./index.css";
import ProdectDetailsMain from "./ProdectDetailsMain";

const ProdectBanners = () => {

  const {productId}= useParams();

  const [productDetails, setproductDetails] = useState(null);


  useEffect(() => {
    fetch("https://faizainc-backend.techknowgram.net/api/products/"+productId)
    .then((response) => response.json())
    .then((data) => setproductDetails(data));
  }, [])
  


  return (
    <>

    {productDetails ? (<>
      <CartBannerComponent title={productDetails.name} />
      <div className="container">
        <ProdectDetailsMain
          title={productDetails.name}
          price={"¥"+ numberWithCommas(productDetails.regular_price)}
          brand={productDetails.model}
          ProductCode="Product 19"
          Availability="In Stock"
          Quality="0"
          productObj={productDetails}
        />
      </div>
    </>) : (<h1>Loading...</h1>) }
     
    </>
  );
};

export default ProdectBanners;
