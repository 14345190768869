import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import MegaBannerImage1 from "../../../assets/images/MegaBannerImage1.jpg";
import MegaBannerImage2 from "../../../assets/images/MegaBannerImage2.jpg";
import MegaBannerImage3 from "../../../assets/images/MegaBannerImage3.jpg";
import MegaBannerImage4 from "../../../assets/images/MegaBannerImage4.jpg";
import MegaBannerImage5 from "../../../assets/images/MegaBannerImage5.jpg";
import "./index.css";

const CategoriesBar = ({ t }) => {
  const [showSubcat, setShowSubcat] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate= useNavigate();

  useEffect(() => {
    axios
    .get("https://faizainc-backend.techknowgram.net/api/categories")
    .then(function (response) {
      // handle success
      console.log(response.data);
      const respData = response.data;
      respData.reverse();
      setCategories(respData);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  }, [])
  

 

  console.log("categories", categories);

  const goToCategoryDetails=(ev, categoryId)=> {
    ev.preventDefault();
    navigate("/categoryDetails/"+categoryId);
  }

  return (
    <div className="bg-primary">
      <div className="container px-5">
        <div className="catsBar">
          <div onClick={() => setShowSubcat(!showSubcat)} className="cats">
            <i className="fa fa-bars"></i>
            {t("Categories")}
            {showSubcat && (
              <div className="subCats bg-primary">
                <ul className="items">
                  <li>
                    <Link to={"/categoryDetails/2"}>{t("hotDeals")} </Link>
                    <i className="fa fa-chevron-right"></i>
                    <div className="mega-subCat-left collect">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage1}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage2}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage3}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage4}
                              alt=""
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-3">
                            <h6>{t("cam")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("comp")}</h6>
                            <h6>{t("mice")}</h6>
                            <h6>{t("mon")}</h6>
                            <h6>{t("print")}</h6>
                            <h6>{t("scann")}</h6>
                            <h6>{t("webcam")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("tabs")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("desk")}</h6>
                            <h6>{t("pc")}</h6>
                            <h6>{t("mc")}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to={"/categoryDetails/4"}>{t("elComp")} </Link>
                    <i className="fa fa-chevron-right"></i>
                    <div className="mega-subCat-left collect">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage1}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage2}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage3}
                              alt=""
                            />
                          </div>
                          <div className="col-md-3">
                            <img
                              className="img-fluid w-100"
                              src={MegaBannerImage4}
                              alt=""
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-3">
                            <h6>{t("cam")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("comp")}</h6>
                            <h6>{t("mice")}</h6>
                            <h6>{t("mon")}</h6>
                            <h6>{t("print")}</h6>
                            <h6>{t("scann")}</h6>
                            <h6>{t("webcam")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("tabs")}</h6>
                          </div>
                          <div className="col-md-3">
                            <h6>{t("desk")}</h6>
                            <h6>{t("pc")}</h6>
                            <h6>{t("mc")}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  {/* <li>
                    <Link to={"/categoryDetails/5"}>{t("tabs")} </Link>
                  </li>
                  <li>
                    <Link to={"/categoryDetails/3"}>{t("smartphone")} </Link>
                  </li>
                  <li>
                    <Link to={"/categoryDetails/6"}>{t("camnPh")} </Link>
                  </li>
                  <li>
                    <Link to={"/categoryDetails/7"}>{t("smntb")} </Link>
                  </li>
                  <li>
                    <Link to={"/categoryDetails/1"}>{t("NewArrivals")}</Link>
                  </li> */}

                  {categories.length &&
                    categories.map((item) => (
                      <li>
                        <Link to={"/categoryDetails/" + item.id}>
                          {item.name}{" "}
                        </Link>
                        {/* <i className="fa fa-chevron-right"></i> */}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          <div className="other-cats">
            <div className="other-cat mega-cat">
              {t("collect")}
              <i className="fa fa-chevron-down"></i>
              <div className="mega-subCat collect">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        className="img-fluid w-100"
                        src={MegaBannerImage1}
                        alt=""
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="img-fluid w-100"
                        src={MegaBannerImage2}
                        alt=""
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="img-fluid w-100"
                        src={MegaBannerImage3}
                        alt=""
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="img-fluid w-100"
                        src={MegaBannerImage4}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-3">
                      <h6>{t("cam")}</h6>
                    </div>
                    <div className="col-md-3">
                      <h6>{t("comp")}</h6>
                      <h6>{t("mice")}</h6>
                      <h6>{t("mon")}</h6>
                      <h6>{t("print")}</h6>
                      <h6>{t("scann")}</h6>
                      <h6>{t("webcam")}</h6>
                    </div>
                    <div className="col-md-3">
                      <h6>{t("tabs")}</h6>
                    </div>
                    <div className="col-md-3">
                      <h6>{t("desk")}</h6>
                      <h6>{t("pc")}</h6>
                      <h6>{t("mc")}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="other-cat mega-cat">
              {t("NewArrivals")}
              <i className="fa fa-chevron-down"></i>
              <div className="mega-subCat newArrival">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      {/* <h6>{t("soft")}</h6> */}
                      {categories.length &&
                        categories.map((item) => <h6 onClick={(ev)=> goToCategoryDetails(ev, item.id)}>{item.name}</h6>)}
                    </div>
                    <div className="col-md-6">
                      {/* <h6>{t("comp")}</h6> */}
                      {/* <ul>
                        <li>
                          <i className="fa fa-chevron-right"></i>
                          <p>{t("print")}</p>
                        </li>
                        <li>
                          <i className="fa fa-chevron-right"></i>
                          <p>{t("scann")}</p>
                        </li>
                        <li>
                          <i className="fa fa-chevron-right"></i>
                          <p>{t("webcam")}</p>
                        </li>
                        <li>
                          <i className="fa fa-chevron-right"></i>
                          <p>{t("scann")}</p>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        className="img-fluid w-100"
                        src={MegaBannerImage5}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="other-cat mega-cat">
              {t("pages")}
              <i className="fa fa-chevron-down"></i>
              {/* <div className="mega-subCat pages">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p>{t("abus")}</p>
                      <p>{t("serv")}</p>
                      <p>{t("fq")}</p>
                      <p>{t("cont")}</p>
                      <p>{t("support")}</p>
                      <p>{t("typ")}</p>
                      <p>{t("phg")}</p>
                    </div>
                    <div className="col-md-6">
                      <p>{t("smap")}</p>
                      <p>{t("p4")}</p>
                      <p>{t("coms")}</p>
                      <p>{t("test")}</p>
                      <p>{t("bseller")}</p>
                      <p>{t("NewArrivals")}</p>
                      <p>{t("mor")}</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="other-cat mega-cat">{t("hots")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesBar;
