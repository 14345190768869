import React from 'react';
import Banners from '../../Components/Banners';
import BestSeller from '../../Components/BestSellerCard';
import CarouselFade from '../../Components/Carousel';
import CartAccount from '../../Components/CartAccount/CartAccount';
import NewArrivals from '../../Components/NewArrivals';

const Home = ({t, login, setlogin, setgolobalKeyword,searchKeyword,setSearchKeyword, sectionRef, homePageSearchedResultProducts}) => {
  
  return (
    <div style={{position: "relative"}}>
        <CarouselFade />
        <Banners t={t} />
        <NewArrivals sectionRef={sectionRef} t={t} homePageSearchedResultProducts={homePageSearchedResultProducts} />
        <BestSeller t={t}/>
        <CartAccount setgolobalKeyword={setgolobalKeyword} searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} login={login} setlogin={setlogin}/>
    </div>
  )
}

export default Home