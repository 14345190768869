import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import axios from "axios";
import { Link } from "react-router-dom";
import "../../Components/Login/Login";
import Myaccount from "../../Components/MyAccount/Myaccount";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import "./Register.css";

const Register = ({ setlogin }) => {
  const phoneRegExp = /^(?:\+?88)?01[13-9]\d{8}$/; //bd phone regex

  const registrationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    password: Yup.string()
      .min(6, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const handleRegistrationSubmit = (values, actions) => {
    console.log("regformvalues", values);
    //network request here
    const regPostObj= {
      name: values.firstName+" "+values.lastName,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation
    }

    const regUrl= "https://faizainc-backend.techknowgram.net/api/auth/register"

    axios.post(regUrl, regPostObj)
    .then((response)=> {
      if (response.status===201) {
        console.log(response);
        actions.resetForm();
        alert("Registration successful");
      }
    })
    .catch((error)=> {
      console.log(error);
      alert("Registration failure");
    });
  };

  return (
    <div>
      <CartBannerComponent title="Register" />
      <div className="container">
        <div className="row content">
          <div className="col-md-9 decration">
            <h1>Register Account</h1>
            <p>
              If you already have an account with us, please login at the
              <Link to="/login"> login page.</Link>
            </p>

            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                password: "",
                password_confirmation: ""
              }}
              validationSchema={registrationSchema}
              onSubmit={handleRegistrationSubmit}
            >
              {({
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <fieldset id="accounts">
                    <legend className="legender">Your Personal Details</legend>
                    <div className="form-group required">
                      <label
                        htmlFor="input-firstname"
                        className="col-sm-2 control-label"
                      >
                        First Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          placeholder="First Name"
                          id="input-firstname"
                          className="from-control"
                        />
                        {touched.firstName && errors.firstName && (
                          <div style={{ color: "red" }}>{errors.firstName}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-lastname"
                        className="col-sm-2 control-label"
                      >
                        Last Name
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                          placeholder="Last Name"
                          id="input-lastname"
                          className="from-control"
                        />
                        {touched.lastName && errors.lastName && (
                          <div style={{ color: "red" }}>{errors.lastName}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-email"
                        className="col-sm-2 control-label"
                      >
                        E-Mail
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="E-Mail"
                          id="input-email"
                          className="from-control"
                        />
                        {touched.email && errors.email && (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-telephone"
                        className="col-sm-2 control-label"
                      >
                        Phone Number
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          placeholder="Telephone"
                          id="input-telephone"
                          className="from-control"
                        />
                        {touched.phoneNumber && errors.phoneNumber && (
                          <div style={{ color: "red" }}>
                            {errors.phoneNumber}
                          </div>
                        )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="legender">Your Password</legend>
                    <div className="form-group required">
                      <label
                        htmlFor="input-password"
                        className="col-sm-2 control-label"
                      >
                        Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Password"
                          id="input-password"
                          className="from-control"
                        />
                        {touched.password && errors.password && (
                          <div style={{ color: "red" }}>{errors.password}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group required">
                      <label
                        htmlFor="input-password_confirmation"
                        className="col-sm-2 control-label"
                      >
                        Password Confirm
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="password_confirmation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password_confirmation}
                          placeholder="Password Confirm"
                          id="input-password_confirmation"
                          className="from-control"
                        />
                        {touched.password_confirmation &&
                          errors.password_confirmation && (
                            <div style={{ color: "red" }}>
                              {errors.password_confirmation}
                            </div>
                          )}
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="legender">Newsletter</legend>
                    <div className="form-group required">
                      <label
                        htmlFor="input-first Name"
                        className="col-sm-2 control-label"
                      >
                        Subscribe
                      </label>
                      <div className="col-sm-10">
                        <label htmlFor="" className="radio-inline">
                          {" "}
                          <input type="radio" name="newslatter" value={1} /> Yes
                        </label>
                        <label htmlFor="" className="radio-inline">
                          {" "}
                          <input
                            type="radio"
                            name="newslatter"
                            value={0}
                            checked="checked"
                          />{" "}
                          No{" "}
                        </label>
                      </div>
                    </div>
                  </fieldset>

                  <div className="buttons">
                    <div className="pull-right">
                      I have read and agree to the
                      <a href="" className="agree">
                        <b> Privacy Policy </b>
                      </a>
                      <input type="checkbox" name="agree" value={1} />
                      <input
                        type="submit"
                        value={"continue"}
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Myaccount setlogin={setlogin} />
        </div>
      </div>
    </div>
  );
};

export default Register;
