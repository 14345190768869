import axios from "axios";

import * as types from "./types";

export const authStart = () => {
  return {
    type: types.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: types.AUTH_SUCCESS,
    idToken: token,
  };
};

export const setAuthUser = (user) => {
  return {
    type: types.AUTH_USER,
    user: user,
  };
};

export const removeAuthUser = () => {
  return {
    type: types.AUTH_USER_REMOVE,
  };
};

export const authFail = (error) => {
  return {
    type: types.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  return {
    type: types.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
    };
    const url = "https://faizainc-backend.techknowgram.net/api/auth/login";

    axios
      .post(url, authData)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(response.data.access_token));
        dispatch(checkAuthTimeout(response.data.expires_in));
      })
      .catch((err) => {
        dispatch(authFail(err.response.data.error));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: types.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authStateCheck = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
